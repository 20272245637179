@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix')          format('embedded-opentype'),
           font-url('#{$file-path}.woff')                format('woff'),
           font-url('#{$file-path}.ttf')                 format('truetype'),
           font-url('#{$file-path}.svg##{$font-family}') format('svg');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix')               format('embedded-opentype'),
           url('#{$file-path}.woff')                     format('woff'),
           url('#{$file-path}.ttf')                      format('truetype'),
           url('#{$file-path}.svg##{$font-family}')      format('svg');
    }
  }
}

@include font-face(Oswald, '../assets/fonts/Oswald-ExtraLight', 200);
@include font-face(Oswald, '../assets/fonts/Oswald-Light', 300);
@include font-face(Oswald, '../assets/fonts/Oswald-Regular');
@include font-face(Oswald, '../assets/fonts/Oswald-Medium', 500);
@include font-face(Oswald, '../assets/fonts/Oswald-SemiBold', 600);
@include font-face(Oswald, '../assets/fonts/Oswald-Bold', bold);

html {
  font-size: 1rem;
}

@include media-breakpoint-up(xs) {
  html {
    font-size: 0.75rem;
  }
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.85rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}
