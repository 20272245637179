$primary: #f98c1b;
$secondary: #0e0d0d;
$background: #3c6ea1;
$navBackground: $secondary;
$countdown: white;
$countdownLastSecond: red;
$title: $primary;
$svgAnimation: $primary;

:export {
  primary: $primary;
  secondary: $secondary;
}
