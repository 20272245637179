@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import './colors.scss';

.checkbox label:after, 
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

// .checkbox .cr,
// .radio .cr {
//     position: relative;
//     // border: 1px solid #a9a9a9;
//     background-color: white;
//     border-radius: .25em;
//     width: 1.5em;
//     height: 1.5em;
//     float: left;
//     margin-right: .5em;
// }

.checkbox .cr,
.radio .cr {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: .25em;
    width: 1.5em;
    height: 1.5em;
    float: left;
    margin-right: .5em;
}

.checkbox .custom-control-input.is-invalid ~ .cr,
.radio .custom-control-input.is-invalid ~ .cr {
    background-color: lighten($danger, 35%);
}

.checkbox input[type="checkbox"]:checked + .cr,
.radio input[type="radio"]:checked + .cr {
    background-color: $primary;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1);
    opacity: 1;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    font-size: .6em;
    line-height: 0;
    color: color-yiq($primary);
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(2);
    opacity: 0;
    transition: all .2s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}
